<template>
    <div class="home-page">
        <container size="lg">
            <h1 class="d-none">
                Peekage | Digital Product Sampling Platform
            </h1>
            <section class="p-x-small-28">
                <Hero
                    image="new/new-home.png"
                    heading-text="Harness the Power of Targeted Consumer Product Experiences"
                    btn-text="Talk to an expert"
                    image-width="w-100"
                >
                    <template #header="{ headingText }">
                        <div>
                            <Heading
                                class="f-w-700 f-s-small-20 m-b-24 m-b-small-16"
                                tag="h2"
                                size="web-x-sm"
                                style="letter-spacing: -0.5px;"
                            >
                                Peekage Consumer Insight Platform
                            </Heading>

                            <Heading
                                color="primary"
                                class="f-w-700 f-s-small-44 m-b-24 m-b-small-16"
                                tag="h2"
                                size="web-xxx-lg"
                                style="letter-spacing: -1px;"
                            >
                                {{ headingText }}
                            </Heading>
                        </div>
                    </template>
                    <template #content>
                        <div class="m-b-32">
                            <p class="font f-s-24 l-h-32 f-w-600 f-s-small-20 m-0">
                                Drive awareness and innovation at scale
                            </p>
                        </div>
                    </template>
                </Hero>
            </section>

            <LogoGarden
                class="p-x-56 p-x-small-16 p-y-96"
                title="Peekage is a versatile product testing platform that enables consumer brands to conduct targeted product sampling and research campaigns with consumers at home.
                Marketing teams leverage Peekage to enhance awareness, drive sales, and increase retail velocity, while product and innovation teams utilize Peekage to conduct in-home product testing for optimizing products, packaging, positioning, and messaging."
            />

            <section data-aos="fade-in" data-aos-easing="linear">
                <MadeEasier />
            </section>

            <section
                class="m-b-20 m-t-120"
                style="overflow: hidden"
            >
                <Feature
                    v-for="item in features.slice(0, 4)"
                    :item="item"
                    :key="item.id"
                />

                <Testimonials :items="quotes" class="m-b-120 p-t-64 p-b-104 p-x-24" />

                <Feature
                    v-for="item in features.slice(4, 6)"
                    :item="item"
                    :key="item.id"
                />
            </section>

            <section data-aos="fade-in" data-aos-easing="linear" class="m-b-144">
                <Empower />
            </section>

            <section data-aos="fade-in" data-aos-easing="linear">
                <CustomerStories :stories="stories" />
            </section>
        </container>

        <WhyPeekage id="WhyPeekage" ref="WhyPeekage" class="m-t-96" />

        <container size="lg" class="m-t-96">
            <section class="p-l-small-0 p-r-small-0" data-aos="fade-in">
                <Banner
                    :btn-text="$device.mobile ? `Let's set a goal` : 'Book a demo'"
                >
                    <template #content>
                        <Heading
                            color="on-color"
                            class="f-w-600 f-s-small-28 l-h-small-36 text-center text-lg-left m-b-0"
                            tag="p"
                            size="x-lg"
                        >
                            Find out how peekage<br>can exceed your business goals
                        </Heading>
                    </template>
                </Banner>
            </section>
        </container>
    </div>
</template>

<script>
import chunk from 'lodash/chunk';
import get from 'lodash/get';
import AOS from 'aos';
import 'aos/dist/aos.css';
import LRUCache from 'lru-cache';
import homeData from '~/content/home/index';
import Feature from '~/components/common/feature';
import Empower from '~/components/common/empower/empower';
import Hero from '~/components/common/hero';
import WhyPeekage from '~/components/common/why-peekage/why-peekage';
import Banner from '~/components/common/banner';
import Testimonials from '~/components/common/testimonials/Testimonials';
import MadeEasier from '~/components/common/made-easier/MadeEasier';
import CustomerStories from '~/components/common/custormer-stories/CustomerStories';
import LogoGarden from '~/components/common/logo-garden/LogoGarden';
import { breadcrumb } from '~/scripts/utils/constants/structured-data/index';
import { Container } from '../xerneas/src/import';

const postsCacheKey = 'BLOG_POSTS';
const { log } = console;

const blogPostsCache = new LRUCache({
    max: 500,
    maxAge: 1000 * 60 * 60 * 24,
});

export default {
    components: {
        Feature,
        Hero,
        Banner,
        Empower,
        WhyPeekage,
        Container,
        MadeEasier,
        Testimonials,
        CustomerStories,
        LogoGarden,
    },
    layout: 'xerneas',
    async asyncData(context) {
        try {
            if (blogPostsCache.has(postsCacheKey)) {
                log('Blog Posts Have Been Cached');
                return {
                    posts: blogPostsCache.get(postsCacheKey),
                };
            }

            const postsResult = await context.app.$axios.get(
                'https://peekage.com/blog/wp-json/wp/v2/posts'
            );
            let posts = get(postsResult, 'data', []);

            posts = posts
                .map(post => {
                    try {
                        const str = get(post, 'content.rendered', '');
                        const regex = /\ssrc=(?:(?:'([^']*)')|(?:"([^"]*)")|([^\s]*))/i;
                        const srcs = (regex.exec(str) || []).filter(Boolean);
                        post = Object.assign({}, post, {
                            image: srcs[1] ? srcs[1] : '',
                        });
                        return post;
                    } catch (error) {
                        return false;
                    }
                })
                .filter(Boolean);

            const postFirstChunk = chunk(posts, 3)[0];
            blogPostsCache.set(postsCacheKey, postFirstChunk);
            return {
                posts: postFirstChunk,
            };
        } catch (error) {
            // return context.error({ statusCode: 500, message: error.message });
        }
    },
    data() {
        return {
            posts: [],
            structuredData: {
                '@context': 'https://schema.org',
                '@type': 'Organization',
                name: 'Peekage',
                alternateName: 'Peekage App',
                url: 'https://peekage.com',
                logo: 'https://peekage.com/logo-default.svg',
                sameAs: [
                    'https://www.facebook.com/peekageApp',
                    'https://twitter.com/peekageapp',
                    'https://www.instagram.com/peekageapp',
                    'https://www.linkedin.com/company/peekage',
                ],
            },
            structuredDataFAQ: {},
            features: homeData.featuresV2,
            quotes: homeData.quotes,
            stories: homeData.stories,
            journey: [
                {
                    title: 'Target',
                    text: 'Consumers sign up on the free Peekage app and complete a series of micro-surveys that creates a comprehensive profile with targeted product recommendations.',
                },
                {
                    title: 'Build',
                    text:
                        'Leveraging 200+ attributes — i.e., age, income, diet, lifestyle, hair, and skin types, and much more — your brand can build the perfect audience and segment on the Peekage platform.',
                },
                {
                    title: 'Engage',
                    text:
                        'Users are rewarded with Peekage tokens for answering micro-surveys and sharing feedback about products they’ve tested. They can exchange their tokens on offers that match their consumer profile, responses, and interests.',
                },
                {
                    title: 'Deliver',
                    text:
                        'Once the user redeems their offer, Peekage will be responsible for delivering your product (digitally or physically) directly to the consumer. This includes in-home deliveries, digital eCommerce codes, and physical trials they can pick up at a local retailer.',
                },
                {
                    title: 'Collect feedback',
                    text:
                        'With direct access to consumers, Peekage is able to send out targeted surveys to users who have tested your products. We’ll gather these insights into a detailed, data-driven report your brand can leverage to grow, expand, and refine your offering.',
                },
                {
                    title: 'Retarget',
                    text:
                        'Once a consumer has tried your product, Peekage can retarget users based on their buying intent, feedback, and behavior. This allows your brand to continue building a relationship with the interested consumer and increase the opportunity for brand loyalty.',
                },
            ],
        };
    },
    head() {
        return {
            title: 'Peekage: Consumer Marketing Platform',
            __dangerouslyDisableSanitizers: ['script'],
            script: [
                {
                    innerHTML: JSON.stringify(this.structuredData),
                    type: 'application/ld+json',
                },
                {
                    innerHTML: JSON.stringify(this.structuredDataFAQ),
                    type: 'application/ld+json',
                },
                {
                    innerHTML: JSON.stringify(breadcrumb()),
                    type: 'application/ld+json',
                },
            ],
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: 'A versatile shopper marketing and product testing platform that enables consumer brands to conduct product sampling and research campaign with targeted consumers at home.',
                },
                {
                    hid: 'keywords',
                    name: 'keywords',
                    content: 'Consumer marketing platform',
                },
                {
                    property: 'og:title',
                    content: 'Peekage | Smart product sampling platform',
                    'data-hid': 'og:title',
                },
                {
                    property: 'og:url',
                    content: 'https://peekage.com',
                    'data-hid': 'og:url',
                },
                {
                    property: 'og:description',
                    content:
                        'Are you interested in a new product—but wanted to try it first? With Peekage, you can',
                    'data-hid': 'og:description',
                },
                {
                    property: 'og:image',
                    content: 'https://peekage.com/opengraph.png',
                    'data-hid': 'og:description',
                },
                {
                    property: 'twitter:title',
                    content: 'Peekage | Smart product sampling platform',
                    vmid: 'twitter:title',
                },
                {
                    property: 'twitter:url',
                    content: 'https://peekage.com',
                    vmid: 'twitter:url',
                },
                {
                    property: 'twitter:description',
                    content:
                        'Are you interested in a new product—but wanted to try it first? With Peekage, you can',
                    vmid: 'twitter:description',
                },
            ],
            link: [
                {
                    rel: 'canonical',
                    href: 'https://peekage.com',
                },
            ],
        };
    },
    mounted() {
        AOS.init();
    },
};
</script>

<style lang="scss" scoped>
.home-page {
    .solutions-box {
        &-container {
        }

        &-item {
            &-slogon {
                max-width: 563px;
            }
        }
    }
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
    .home-page {
        .logo-garden {
            background: unset;
        }
    }
}
</style>
